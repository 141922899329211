import Enumeration from "./enumeration";

/**
 * List of ordering services
 * @returns {Enumeration<statuses>}
 */
export const orderingServices = new Enumeration({
  DELIVERY: "DELIVERY",
  PICKUP: "PICKUP",
  DINE_IN: "DINE_IN",
});

/**
 * List of available states
 * @returns {Enumeration<statuses>}
 */
export const states = new Enumeration({
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
  PAUSED: "PAUSED",
});
