import {
  IFetchCustomPaymentMethodsByOutletRequest,
  IFetchCustomPaymentMethodsByOutletContract,
  IFetchCustomPaymentMethodsByOutletResponse,
  IFetchCustomPaymentMethodsByOutletBodyPayload,
} from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { http } from "../../http";

export class FetchCustomPaymentMethodsByOutletError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchCustomPaymentMethodsByOutlet");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

/**
 * {@link https://chatfood.stoplight.io/docs/chatfood-api-dashboard/bda632b3b8c73-get-custom-payment-methods-of-outlet API Contract}
 */
export async function fetchCustomPaymentMethodsByOutlet(
  request: IFetchCustomPaymentMethodsByOutletRequest
): Promise<IFetchCustomPaymentMethodsByOutletResponse> {
  try {
    const params: IFetchCustomPaymentMethodsByOutletBodyPayload = {
      ordering_service: request.orderingMode,
    };

    const { data } = await http.get<IFetchCustomPaymentMethodsByOutletContract>(
      `v1/businesses/${request.businessId}/payments/outlets/${request.outletId}/custom-methods`,
      { params }
    );

    return data.data
      .map((paymentMethod) => ({
        id: paymentMethod.id,
        name: paymentMethod.name,
        isEnabled: paymentMethod.is_enabled,
        isVisibleToCustomer: paymentMethod.is_visible_to_customer,
      }))
      .sort((a, b) =>
        a.name.localeCompare(b.name, "en", {
          numeric: true,
        })
      );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchCustomPaymentMethodsByOutletError(e);
  }
}
