



































































































































































































































import { Button, Toast } from "@/design-system";
import { t } from "@/i18n";
import {
  AtomCheckbox,
  AtomModal,
  AtomText,
  AtomTextSizeEnum,
} from "@/v2/design-system";
import { OrderingServiceEnum } from "@/v2/enum";
import { func, object, string } from "@/v2/util/prop/type";
import { isOneOf } from "@/v2/util/prop/validator";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import {
  IOutletSettingPaymentMethodProps,
  IOutletSettingPaymentMethods,
} from "./props";
import { OutletPaymentMethodCheckoutComAllowedCountries } from "./checkout-com-allowed-countries";
import { isFlagEnabled } from "@/v2/core/feature-flag";
import { OutletSettingPrepaidMethod } from "@/v2/module/outlet/setting/prepaid-method";

const css = (element: string = "", modifier: string = ""): string =>
  `outlet-setting-payment-method${element ? `__${element}` : ""}${
    modifier ? `--${modifier}` : ""
  }`;

export default defineComponent<IOutletSettingPaymentMethodProps>({
  name: "OutletSettingPaymentMethod",
  components: {
    AtomCheckbox,
    AtomText,
    AtomModal,
    Button,
    OutletSettingPrepaidMethod,
  },
  props: {
    initalState: object().isRequired().use(),
    businessCountryCode: string().isRequired().use(),
    orderingService: string()
      .isRequired()
      .setValidator(isOneOf(Object.values(OrderingServiceEnum)))
      .use(),
    onChange: func().use(),
  },
  setup(props) {
    const isMandatoryCardOnlineFlag = ref(false);

    onMounted(() => {
      isMandatoryCardOnlineFlag.value = isFlagEnabled(
        "mandatory-card-online-fe",
        false
      );
    });

    const onlineTermsLink =
      "http://docs.chatfood.io/en/articles/4373483-chatfood-payment-powered-by-checkout-com-terms-and-conditions";
    const chargebackTermsLink =
      "http://docs.chatfood.io/en/articles/4373710-chargebacks-and-inquiries";
    const learnMoreLink =
      "http://docs.chatfood.io/en/collections/2519315-checkout-and-payments";

    const modalOpenState = ref(false);
    const checkedState = reactive({ ...props.initalState });

    const modalAgreementState = reactive({
      charge: false,
      onlineTerms: false,
      chargebackTerms: false,
    });

    const hasOwn = (key: string) =>
      Object.prototype.hasOwnProperty.call(props.initalState, key);

    const isSupportsCardOnline = computed(() => {
      return hasOwn("prepaid");
    });
    const isSupportsCardOnDelivery = computed(() => {
      return hasOwn("card");
    });
    const isSupportsCashOnDelivery = computed(() => {
      return hasOwn("cash");
    });
    const isDelivery = computed(
      () => props.orderingService === OrderingServiceEnum.DELIVERY
    );
    const isPickup = computed(
      () => props.orderingService === OrderingServiceEnum.PICKUP
    );
    const isDineIn = computed(
      () => props.orderingService === OrderingServiceEnum.DINE_IN
    );

    const fixedCharge: string = "USD 0.27";

    const cardOnlinePercentageCharge = computed((): string => {
      return props.businessCountryCode === "AE" ? "2.95%" : "3.5%";
    });

    const cardOnlineTermsAgreed = computed((): boolean => {
      return !Object.values(modalAgreementState).some(
        (agree) => agree === false
      );
    });

    function openCardOnlineModal() {
      modalOpenState.value = true;
    }

    function closeCardOnlineModal() {
      modalOpenState.value = false;
    }

    function onChangeCardOnline(checked: boolean): void {
      if (checkedState.prepaid) {
        updateCheckedState("prepaid", false);
      } else {
        openCardOnlineModal();
      }
    }

    function resetModalAgreementState(): void {
      modalAgreementState.charge = false;
      modalAgreementState.onlineTerms = false;
      modalAgreementState.chargebackTerms = false;
    }

    function onAcceptTermsCardOnline(): void {
      resetModalAgreementState();
      closeCardOnlineModal();
      updateCheckedState("prepaid", true);
    }

    function onRejectTermsCardOnline(): void {
      resetModalAgreementState();
      closeCardOnlineModal();
    }

    function lastSelectedMethodToast() {
      new Toast().create({
        type: "error",
        title: t("label.oops"),
        text: t("c.outlets.select_at_least_one_payment"),
      });
    }

    const isLastMethodSelected = computed((): boolean => {
      return (
        Object.values(checkedState).filter((method) => method === true)
          .length === 1
      );
    });

    function updateCheckedState(
      methodName: keyof IOutletSettingPaymentMethods,
      enabled: boolean
    ): void {
      if (isLastMethodSelected.value && enabled === false) {
        lastSelectedMethodToast();
      } else {
        checkedState[methodName] = enabled;
        props.onChange(methodName, enabled);
      }
    }

    return {
      css,
      t,
      isDelivery,
      isPickup,
      isDineIn,
      onlineTermsLink,
      chargebackTermsLink,
      learnMoreLink,
      checkedState,
      OutletPaymentMethodCheckoutComAllowedCountries,
      modalOpenState,
      modalAgreementState,
      AtomTextSizeEnum,
      cardOnlinePercentageCharge,
      fixedCharge,
      cardOnlineTermsAgreed,
      openCardOnlineModal,
      closeCardOnlineModal,
      onChangeCardOnline,
      onAcceptTermsCardOnline,
      onRejectTermsCardOnline,
      updateCheckedState,
      isSupportsCardOnline,
      isSupportsCardOnDelivery,
      isSupportsCashOnDelivery,
      isMandatoryCardOnlineFlag,
    };
  },
});
