<template>
  <div class="mt-5">
    <p class="mb-0 f-w-500">
      {{ $t("c.outlets.operation_settings") }}
    </p>
    <p class="text-muted mb-4">
      {{
        $t(
          `c.outlets.operation_settings_${orderingService.toLowerCase()}_subtitle`
        )
      }}
    </p>
    <div class="row">
      <div class="col-lg-6 mb-4">
        <FormControl
          :label="$t('c.outlets.preparation_minutes')"
          :error="errorMessageFor('preparationMinutes')"
        >
          <Input
            v-model.number="operationSettings.preparationMinutes"
            data-test="preparation-time"
            :is-loading="isLoading.preparationMinutes"
            :has-errors="hasErrorFor('preparationMinutes')"
            @change="update('preparationMinutes')"
          />
        </FormControl>
      </div>
      <div v-if="isDelivery" class="col-lg-6 mb-4">
        <FormControl
          :label="$t('c.outlets.preparation_total_minutes')"
          :error="errorMessageFor('estimatedCompletionMinutes')"
        >
          <Input
            v-model.number="operationSettings.estimatedCompletionMinutes"
            data-test="estimated-completion-minute"
            :is-loading="isLoading.estimatedCompletionMinutes"
            :has-errors="hasErrorFor('estimatedCompletionMinutes')"
            @change="update('estimatedCompletionMinutes')"
          />
        </FormControl>
      </div>
    </div>
  </div>
</template>

<script>
import UPDATE_PREPARATION_MINUTES from "@/modules/outlets/graphql/UpdateOutletOrderingServiceQuietPreparationMinutes.gql";
import UPDATE_ESTIMATED_COMPLETION_MINUTES from "@/modules/outlets/graphql/UpdateOutletOrderingServiceQuietEstimatedCompletionMinutes.gql";
import { orderingServices } from "@/utils/enums/ordering-services";
import { FormControl, Input, Toast } from "@ds";
import { has, pathOr, isEmpty, propOr } from "ramda";

export default {
  name: "OperationSettings",
  components: {
    FormControl,
    Input,
  },
  props: {
    outletId: {
      type: String,
      required: true,
    },
    orderingService: {
      type: String,
      required: true,
      validator: (val) => orderingServices.has(val),
    },
    preparationMinutes: {
      type: Number,
      default: 0,
    },
    estimatedCompletionMinutes: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    operationSettings: {
      preparationMinutes: 0,
      estimatedCompletionMinutes: 0,
    },
    isLoading: {
      preparationMinutes: false,
      estimatedCompletionMinutes: false,
    },
    errors: {},
  }),
  computed: {
    isDelivery() {
      return this.orderingService === orderingServices.DELIVERY;
    },
  },
  watch: {
    preparationMinutes: {
      handler(val) {
        this.operationSettings.preparationMinutes = val;
      },
      immediate: true,
    },
    estimatedCompletionMinutes: {
      handler(val) {
        this.operationSettings.estimatedCompletionMinutes = val;
      },
      immediate: true,
    },
  },
  methods: {
    async update(item) {
      this.isLoading[item] = true;

      try {
        const updatePreparationMinutes = {
          mutation: UPDATE_PREPARATION_MINUTES,
          variables: {
            outletId: this.outletId,
            orderingService: this.orderingService,
            preparationMinutes: this.operationSettings.preparationMinutes,
          },
        };

        const updateEstimatedCompletionMinutes = {
          mutation: UPDATE_ESTIMATED_COMPLETION_MINUTES,
          variables: {
            outletId: this.outletId,
            estimatedCompletionMinutes:
              this.operationSettings.estimatedCompletionMinutes,
          },
        };

        const res = await this.$apollo.mutate(
          item === "preparationMinutes"
            ? updatePreparationMinutes
            : updateEstimatedCompletionMinutes
        );

        if (has("errors", res)) {
          throw res.errors;
        }

        this.errors = {};

        new Toast().create({
          type: "success",
          title: this.$t("label.success"),
          text: this.$t("message.record_updated"),
        });
      } catch (error) {
        this.operationSettings[item] = this[item];

        const errors = error.graphQLErrors ?? error;
        this.errors = pathOr(null, ["0", "extensions", "validation"], errors);

        new Toast().create({
          type: "error",
          title: this.$t("label.oops"),
          text: this.$t("message.general_failure"),
        });
      } finally {
        this.isLoading[item] = false;
      }
    },
    errorMessageFor(item) {
      return pathOr("", [item, 0], this.errors);
    },
    hasErrorFor(item) {
      return !isEmpty(propOr("", item, this.errors));
    },
  },
};
</script>
