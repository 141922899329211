import {
  IUpdateCustomPaymentMethodByOutletRequest,
  IUpdateCustomPaymentMethodByOutletBodyParams,
} from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { http } from "../../http";

export class UpdateCustomPaymentMethodByOutletError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: UpdateCustomPaymentMethodByOutlet");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

/**
 * {@link https://chatfood.stoplight.io/docs/chatfood-api-dashboard/branches/main/b54ee581d2391-sync-custom-payment-method-for-outlet API Contract}
 */
export async function updateCustomPaymentMethodByOutlet(
  request: IUpdateCustomPaymentMethodByOutletRequest
): Promise<void> {
  try {
    const params: IUpdateCustomPaymentMethodByOutletBodyParams = {
      ordering_service: request.orderingMode,
      is_enabled: request.isEnabled,
      is_visible_to_customer: request.isVisibleToCustomer,
    };

    await http.put(
      `v1/businesses/${request.businessId}/payments/outlets/${request.outletId}/custom-methods/${request.paymentMethodId}/sync`,
      { ...params }
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new UpdateCustomPaymentMethodByOutletError(e);
  }
}
