





























































































import { FormControl, Toast, Switcher } from "@/design-system";
import { AtomSelect } from "@/v2/new-design-system";
import { updateOutletOrderingSchedule } from "@/v2/repo/update-outlet-ordering-schedule";
import { defineComponent, ref, watch } from "@vue/composition-api";
import { t, tc } from "@/i18n";

export default defineComponent({
  name: "OrderScheduling",
  components: {
    FormControl,
    Switcher,
    AtomSelect,
  },
  props: {
    outletId: {
      type: String,
      required: true,
    },
    orderingService: {
      type: String,
      required: true,
    },
    acceptsPreOrders: {
      type: Boolean,
      required: true,
    },
    acceptsAsapOrders: {
      type: Boolean,
      required: true,
    },
    acceptsSameDayPreOrders: {
      type: Boolean,
      required: true,
    },
    minLeadingTime: {
      type: Number,
      default: 5,
    },
    maxDaysAdvance: {
      type: Number,
      default: 2,
    },
    slotLengthMin: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const acceptsAsapOrdersLocal = ref<boolean>(props.acceptsAsapOrders);
    const acceptsPreOrdersLocal = ref<boolean>(props.acceptsPreOrders);
    const minLeadingTimeLocal = ref<number>(props.minLeadingTime);
    const maxDaysAdvanceLocal = ref<number>(props.maxDaysAdvance);
    const acceptsSameDayPreOrdersLocal = ref<boolean>(
      props.acceptsSameDayPreOrders
    );
    const slotLengthMinLocal = ref<number>(props.slotLengthMin);
    const leadTime = ref([15, 30, 60, 120, 180, 240, 300, 360, 420, 480]);
    const daysAdvance = ref(Array.from({ length: 31 }, (_, i) => i + 0));
    const slotLenght = ref([30, 60, 120, 180, 240, 300, 360]);

    async function updateSchedule() {
      try {
        const result = await updateOutletOrderingSchedule(
          props.outletId,
          props.orderingService,
          acceptsAsapOrdersLocal.value,
          acceptsPreOrdersLocal.value,
          acceptsSameDayPreOrdersLocal.value,
          minLeadingTimeLocal.value,
          maxDaysAdvanceLocal.value,
          slotLengthMinLocal.value
        );
        if (result) {
          new Toast().create({
            type: "success",
            title: t("label.success"),
            text: t("message.record_updated"),
          });
        }
      } catch (error) {
        acceptsAsapOrdersLocal.value = props.acceptsAsapOrders;
        acceptsPreOrdersLocal.value = props.acceptsPreOrders;
        acceptsSameDayPreOrdersLocal.value = props.acceptsSameDayPreOrders;
        minLeadingTimeLocal.value = props.minLeadingTime;
        maxDaysAdvanceLocal.value = props.maxDaysAdvance;
        slotLengthMinLocal.value = props.slotLengthMin;

        new Toast().create({
          type: "error",
          title: t("label.oops"),
          text: t("message.general_failure"),
        });
      }
    }

    function getTimeOptionLabel(time: number) {
      return time > 59
        ? tc("label.x_hours", time / 60)
        : `${time} ${t("label.min")}`;
    }

    watch(
      [
        () => minLeadingTimeLocal.value,
        () => maxDaysAdvanceLocal.value,
        () => slotLengthMinLocal.value,
      ],
      async (oldVals, updateValues) => {
        if (
          updateValues[0] != minLeadingTimeLocal.value ||
          updateValues[1] != maxDaysAdvanceLocal.value ||
          updateValues[2] != slotLengthMinLocal.value
        ) {
          await updateSchedule();
        }
      }
    );

    watch(
      [
        () => props.acceptsAsapOrders,
        () => props.acceptsPreOrders,
        () => props.acceptsSameDayPreOrders,
        () => props.minLeadingTime,
        () => props.maxDaysAdvance,
        () => props.slotLengthMin,
      ],
      () => {
        acceptsAsapOrdersLocal.value = props.acceptsAsapOrders;
        acceptsPreOrdersLocal.value = props.acceptsPreOrders;
        acceptsSameDayPreOrdersLocal.value = props.acceptsSameDayPreOrders;
        maxDaysAdvanceLocal.value = props.maxDaysAdvance;
        minLeadingTimeLocal.value = props.minLeadingTime;
        slotLengthMinLocal.value = props.slotLengthMin;
      },
      {
        immediate: true,
      }
    );

    return {
      updateSchedule,
      getTimeOptionLabel,
      acceptsAsapOrdersLocal,
      acceptsPreOrdersLocal,
      acceptsSameDayPreOrdersLocal,
      minLeadingTimeLocal,
      maxDaysAdvanceLocal,
      slotLengthMinLocal,
      leadTime,
      daysAdvance,
      slotLenght,
    };
  },
});
