
















































import { t } from "@/i18n";
import { defineComponent } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomIcon,
  AtomLink,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  AtomLinkTypeEnum,
  AtomLinkSizeEnum,
} from "@/v2/new-design-system";

const css = bemBuilder("outlet-setting-prepaid-method");

export default defineComponent({
  name: "OutletSettingPrepaidMethod",
  components: {
    AtomIcon,
    AtomText,
    AtomLink,
  },
  props: {
    isActive: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const link =
      "https://docs.chatfood.io/en/articles/4373483-chatfood-payments-powered-by-checkout-com-terms-and-conditions";

    return {
      t,
      css,
      link,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomLinkTypeEnum,
      AtomLinkSizeEnum,
    };
  },
});
