<template>
  <div class="clearfix intercomSpace">
    <LoadingZone :loading="isLoading">
      <div v-if="!isLoading">
        <div class="row wrap-qrcode">
          <div class="col-xl">
            <ChangeStatus
              class="mb-5"
              :outlet-id="outletId"
              :ordering-service="orderingServiceEnum"
              :state="orderingService.state"
              @updated="updateStatus"
            />
            <div :class="{ 'block-features': isDisabled }">
              <!-- TODO: remove this old component once offline payment methods feature is released -->
              <OutletSettingPaymentMethod
                v-if="!isDineIn"
                :business-country-code="business.country"
                :inital-state="initialPaymentMethodState"
                :ordering-service="orderingServiceEnum"
                :on-change="updatePaymentMethodStatus"
              />
              <OutletPaymentMethod
                v-else
                :business-id="businessId"
                :outlet-id="outletId"
                :ordering-mode="orderService"
              />
              <OpeningHours
                :outlet-id="outletId"
                :ordering-service="orderingService"
              />
              <div v-if="!isDineIn" style="max-width: 469px">
                <OperationSettings
                  :outlet-id="outletId"
                  :ordering-service="orderingServiceEnum"
                  :preparation-minutes="
                    Number(operationSettings.preparationMinutes)
                  "
                  :estimated-completion-minutes="
                    Number(operationSettings.estimatedCompletionMinutes)
                  "
                />
              </div>
              <div style="max-width: 469px">
                <OrderScheduling
                  v-if="!isDineIn"
                  data-test="order-scheduling"
                  :outlet-id="outletId"
                  :ordering-service="orderingServiceEnum"
                  :accepts-pre-orders="orderingService.acceptsPreOrders"
                  :accepts-asap-orders="orderingService.acceptsAsapOrders"
                  :accepts-same-day-pre-orders="
                    orderingService.acceptsSameDayPreOrders
                  "
                  :min-leading-time="orderingService.minimumLeadTimeInMinutes"
                  :max-days-advance="orderingService.maximumDaysInAdvance"
                  :slot-length-min="orderingService.slotLengthInMinutes"
                />
              </div>
              <ServiceCharge
                :outlet-id="outletId"
                :ordering-service="orderService"
              />
            </div>
          </div>
        </div>
      </div>
    </LoadingZone>
  </div>
</template>

<script>
import GET_OUTLET_ORDERING_SERVICE from "@/modules/outlets/graphql/GetOutletOrderingService.gql";
import ChangeStatus from "@/modules/outlets/ui/components/ordering-service/ChangeStatus.vue";
import OpeningHours from "@/modules/outlets/ui/components/ordering-service/opening-hours/Index.vue";
import OperationSettings from "@/modules/outlets/ui/components/ordering-service/OperationSettings.vue";
import OrderScheduling from "@/modules/outlets/ui/components/ordering-service/OrderScheduling.vue";
import ServiceCharge from "@/modules/outlets/ui/components/ordering-service/ServiceCharge.vue";
import { orderingServices, states } from "@/utils/enums/ordering-services";
import { LoadingZone, Toast } from "@/design-system";
import { pathOr } from "ramda";
import { computed } from "@vue/composition-api";
import { OutletSettingPaymentMethod } from "@/v2/module/outlet/setting/payment-method";
import { updateOutletPaymentMethod } from "@/v2/repo/update-outlet-payment-method";
import { OutletPaymentMethod } from "@/v2/module/outlet/payment-method";
import { mapGetters } from "vuex";
import { OrderingServiceOutletEnum } from "@/v2/enum";

export default {
  name: "OrderingService",
  components: {
    LoadingZone,
    ChangeStatus,
    OpeningHours,
    OperationSettings,
    OrderScheduling,
    ServiceCharge,
    OutletSettingPaymentMethod,
    OutletPaymentMethod,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    orderingServiceSlug: {
      type: String,
      required: true,
    },
  },
  apollo: {
    outlet: {
      query: GET_OUTLET_ORDERING_SERVICE,
      variables() {
        return {
          outletId: this.outletId,
          orderingServiceEnum: this.orderingServiceSlug
            .toUpperCase()
            .replace(/-/g, "_"),
        };
      },
    },
  },
  setup(props) {
    const orderService = computed(() =>
      props.orderingServiceSlug === "dine_in"
        ? OrderingServiceOutletEnum.DINE_IN
        : props.orderingServiceSlug
    );

    return {
      orderService,
    };
  },
  computed: {
    ...mapGetters({
      businesses: "businesses/getData",
    }),
    business() {
      return Object.values(this.businesses).find(
        (obj) => obj.id === this.businessId
      );
    },
    isDineIn() {
      return this.orderingServiceEnum === orderingServices.DINE_IN;
    },
    isDisabled() {
      return this.orderingService.state === states.DISABLED;
    },
    isLoading() {
      return this.$apollo.queries.outlet.loading;
    },
    orderingService() {
      return pathOr({}, ["orderingServices", 0], this.outlet);
    },
    orderingServiceEnum() {
      return this.orderingService.orderingServiceEnum;
    },
    operationSettings() {
      return pathOr({}, ["orderingServiceSettings", 0], this.orderingService);
    },
    initialPaymentMethodState() {
      return Object.values(this.orderingService.paymentMethods).reduce(
        (total, current) => {
          total[current.name.toLowerCase()] = current.isEnabled;
          return total;
        },
        {}
      );
    },
  },
  methods: {
    async updatePaymentMethodStatus(methodName, enabled) {
      const paymentMethodId = Object.values(
        this.orderingService.paymentMethods
      ).find(
        (paymentMethod) => paymentMethod.name.toLowerCase() === methodName
      ).id;

      try {
        const [result, error] = await updateOutletPaymentMethod(
          this.outletId,
          paymentMethodId,
          enabled
        );

        if (error) throw error;

        if (result.updatePaymentMethodStatus) {
          new Toast().create({
            type: "success",
            title: this.$t("label.success"),
            text: this.$t("message.record_updated"),
          });
        }
      } catch (error) {
        new Toast().create({
          type: "error",
          title: this.$t("label.oops"),
          text: this.$t("message.general_failure"),
        });
      }
    },
    updateStatus(val) {
      this.outlet.orderingServices[0].state = val;
    },
    refetch() {
      this.$apollo.queries.outlet.refresh();
    },
  },
};
</script>

<style scoped lang="scss">
.block-features {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: -12.5px;
    width: calc(100% + 25px);
    height: 100%;
    z-index: 5;
    background: rgba(255, 255, 255, 0.9);
  }
}

@media (max-width: 1300px) {
  .wrap-qrcode {
    display: block;
  }
}
</style>
