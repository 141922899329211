import { IRepoErrors } from "../../errors";
import { http } from "../../http";
import { OrderingServiceOutletEnum } from "@/v2/enum";

export class UpdateServiceChargeError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: UpdateServiceCharge");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function updateServiceCharge(
  outletId: string,
  orderingService: OrderingServiceOutletEnum,
  serviceCharge: number
): Promise<boolean> {
  let response: boolean;

  try {
    response = await http.post(
      `/api/v3/outlets/${outletId}/services/${orderingService}/generic-service-charge`,
      {
        value: serviceCharge,
      }
    );
  } catch (err: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(err.isAxiosError);

    if (!isAxiosError) {
      throw err;
    }

    throw new UpdateServiceChargeError(error);
  }

  return Boolean(response);
}
