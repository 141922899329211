




























































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  PropType,
  ref,
  onMounted,
} from "@vue/composition-api";
import {
  AtomIcon,
  AtomLoading,
  AtomCheckbox,
  AtomTooltip,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import { IDomainOrderingMode } from "@/v2/domain/ordering-mode";
import {
  fetchCustomPaymentMethodsByOutlet,
  IFetchCustomPaymentMethodsByOutletResponse,
} from "@/v2/repo/custom-payment-method/fetch-custom-payment-methods-by-outlet";
import { updateCustomPaymentMethodByOutlet } from "@/v2/repo/custom-payment-method/update-custom-payment-method-by-outlet";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { report } from "@chatfood/bug-reporter";
import { t } from "@/i18n";

const css = bemBuilder("outlet-payment-methods");

export default defineComponent({
  name: "OutletPaymentMethod",
  components: {
    AtomIcon,
    AtomLoading,
    AtomCheckbox,
    AtomText,
    AtomTooltip,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    orderingMode: {
      type: String as PropType<IDomainOrderingMode>,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const paymentMethods = ref<IFetchCustomPaymentMethodsByOutletResponse>([]);

    onMounted(() => getPaymentMethods());

    async function getPaymentMethods() {
      isLoading.value = true;

      try {
        paymentMethods.value = await fetchCustomPaymentMethodsByOutlet({
          businessId: props.businessId,
          outletId: props.outletId,
          orderingMode: props.orderingMode,
        });
      } catch (e: any) {
        report(e);
        generalErrorToast();
      } finally {
        isLoading.value = false;
      }
    }

    async function onUpdatePaymentMethod(
      type: "state" | "visibility",
      method: IFetchCustomPaymentMethodsByOutletResponse[0]
    ) {
      let isEnabled = method.isEnabled;
      let isVisibleToCustomer = method.isVisibleToCustomer;

      if (type === "state") {
        isEnabled = !isEnabled;
      }

      if (type === "visibility") {
        isVisibleToCustomer = !isVisibleToCustomer;
      }

      try {
        await updateCustomPaymentMethodByOutlet({
          businessId: props.businessId,
          outletId: props.outletId,
          orderingMode: props.orderingMode,
          paymentMethodId: method.id,
          isEnabled,
          isVisibleToCustomer,
        });

        new Toast().create({
          type: "success",
          text: t(
            "module.outlets.payment_methods.toast_success_update_message"
          ),
        });

        paymentMethods.value = paymentMethods.value.map((obj) => {
          if (obj.id === method.id) {
            obj.isEnabled = isEnabled;
            obj.isVisibleToCustomer = isVisibleToCustomer;
          }

          return obj;
        });
      } catch (e: any) {
        report(e);
        generalErrorToast();
      } finally {
        isLoading.value = false;
      }
    }

    function iconVisibility(state: boolean) {
      return state ? "eye" : "eye-slash";
    }

    return {
      t,
      css,
      isLoading,
      paymentMethods,
      iconVisibility,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      onUpdatePaymentMethod,
    };
  },
});
