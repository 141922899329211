import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";
import { http } from "../../http";
import { OrderingServiceOutletEnum } from "@/v2/enum";

export class FetchServiceChargeError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchServiceCharge");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

interface IFetchServiceCharge {
  serviceCharge: number;
}

export async function fetchServiceCharge(
  outletId: string,
  orderingService: OrderingServiceOutletEnum
): Promise<IFetchServiceCharge> {
  let response: AxiosResponse;

  try {
    response = await http.get(
      `/api/v3/outlets/${outletId}/services/${orderingService}/generic-service-charge`
    );
  } catch (err: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(err.isAxiosError);

    if (!isAxiosError) {
      throw err;
    }

    throw new FetchServiceChargeError(error);
  }

  return {
    serviceCharge: response.data.value,
  };
}
