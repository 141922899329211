interface Response {
  __typename: string;
}

enum RESPONSE_TYPE {
  ERROR = "Error",
  SUCCESS = "Success",
}

export function graphqlExtractCustomResponse(
  response: Response
): Record<string, any> {
  const { __typename: type, ...rest } = response;
  return type === RESPONSE_TYPE.ERROR ? { errors: rest } : rest;
}
