import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { RepoErrorEnum } from "../repo-error.enum";
import { graphqlExtractCustomResponse } from "@/v2/util/graphql-extract-custom-response";

export async function updateOutletOrderingSchedule(
  outletId: string,
  orderingService: string,
  acceptsAsapOrders: boolean,
  acceptsPreOrders: boolean,
  acceptsSameDayPreOrders: boolean,
  minimumLeadTimeInMinutes: number,
  maximumDaysInAdvance: number,
  slotLengthInMinutes: number
): Promise<boolean> {
  let response: any;

  try {
    response = await graphql.mutate({
      mutation,
      variables: {
        outletId,
        orderingService,
        orderingSchedule: {
          acceptsAsapOrders,
          acceptsPreOrders,
          acceptsSameDayPreOrders,
          minimumLeadTimeInMinutes,
          maximumDaysInAdvance,
          slotLengthInMinutes,
        },
      },
    });
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
  const extractedResponse = graphqlExtractCustomResponse(
    response.data.updateOutletOrderingSchedule
  );

  if (extractedResponse.errors) {
    throw new Error(extractedResponse.errors.code);
  }

  return Boolean(extractedResponse.status);
}
