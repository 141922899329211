import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { RepoErrorEnum } from "../repo-error.enum";
import { GraphQLError } from "graphql";

export async function updateOutletPaymentMethod(
  outletId: string,
  paymentMethodId: string,
  isEnabled: boolean
): Promise<[IUpdateOutletUpdatePaymentMethod, Array<GraphQLError>]> {
  try {
    const response = await graphql.mutate({
      mutation,
      variables: {
        outletId,
        paymentMethodId,
        isEnabled,
      },
    });

    // @ts-ignore
    return [response.data, response?.errors];
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}

interface IUpdateOutletUpdatePaymentMethod {
  updatePaymentMethodStatus: boolean | null;
}
