




























import {
  defineComponent,
  ref,
  PropType,
  onMounted,
} from "@vue/composition-api";
import { updateServiceCharge } from "@/v2/repo/service-charge/update-service-charge";
import { fetchServiceCharge } from "@/v2/repo/service-charge/fetch-service-charge";
import { OrderingServiceOutletEnum } from "@/v2/enum";
import { FormControl, Input, Toast } from "@/design-system";
import { t } from "@/i18n";
import { report } from "@chatfood/bug-reporter";

interface IServiceCharge {
  serviceCharge: number;
}

export default defineComponent({
  name: "ServiceCharge",
  components: {
    FormControl,
    Input,
  },
  props: {
    outletId: {
      type: String,
      required: true,
    },
    orderingService: {
      type: String as PropType<OrderingServiceOutletEnum>,
      required: true,
    },
  },
  setup(props) {
    const updatedServiceCharge = ref(0);
    const serviceCharge = ref(0);
    const isLoading = ref(false);
    const errors = ref({});

    async function updateServiceChargeValue(inputValue: any) {
      updatedServiceCharge.value =
        inputValue === "" ? 0 : parseFloat(inputValue);

      isLoading.value = true;
      let response = false;
      try {
        response = await updateServiceCharge(
          props.outletId,
          props.orderingService,
          updatedServiceCharge.value
        );
      } catch (e: any) {
        errors.value = {
          serviceCharge: t("c.outlets.service_charge.error_message_update"),
        };
        updatedServiceCharge.value = serviceCharge.value;

        report(e);
      } finally {
        isLoading.value = false;
      }

      if (!response) return;

      errors.value = {};
      serviceCharge.value = updatedServiceCharge.value;
      new Toast().create({
        type: "success",
        title: t("label.success"),
        text: t("message.record_updated"),
      });
    }

    async function getServiceCharge() {
      let response = null;
      isLoading.value = true;
      try {
        response = await fetchServiceCharge(
          props.outletId,
          props.orderingService
        );
      } catch (error) {
        updatedServiceCharge.value = 0;
      } finally {
        isLoading.value = false;
      }

      if (!response) return;

      serviceCharge.value = response.serviceCharge;
      updatedServiceCharge.value = response.serviceCharge;
    }

    function getSubtitle(service: OrderingServiceOutletEnum) {
      switch (service) {
        case OrderingServiceOutletEnum.DELIVERY:
          return t(`c.outlets.service_charge.subtitle_delivery`);
        case OrderingServiceOutletEnum.PICKUP:
          return t(`c.outlets.service_charge.subtitle_pickup`);
        default:
          return t(`c.outlets.service_charge.subtitle_dinein`);
      }
    }

    onMounted(() => {
      getServiceCharge();
    });

    return {
      errors,
      isLoading,
      updatedServiceCharge,
      getSubtitle,
      updateServiceChargeValue,
    };
  },
});
