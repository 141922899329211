

















import EDIT_ORDERING_SERVICE_STATE from "@/modules/outlets/graphql/EditOutletOrderingService.gql";
import { Switcher, Toast } from "@/design-system";
import { defineComponent, ref, onMounted } from "@vue/composition-api";
// eslint-disable-next-line
import { PropType } from "@vue/composition-api";
import UIState from "@/modules/outlets/state/ui";
import { OrderingModeStates, OrderingModes } from "@/modules/outlets/enums";
import { OrderingServiceEnum } from "@/v2/enum";
import { translate } from "@/i18n";

export default defineComponent({
  name: "ChangeStatus",
  components: {
    Switcher,
  },
  props: {
    outletId: {
      type: String,
      required: true,
    },
    orderingService: {
      type: String as PropType<OrderingServiceEnum>,
      required: true,
    },
    state: {
      type: String as PropType<OrderingModeStates>,
      required: true,
    },
  },
  setup(props, context) {
    const isChecked = ref<boolean>(false);

    onMounted(() => {
      isChecked.value = props.state !== OrderingModeStates.DISABLED;
    });

    const { setDeliveryModeState, setPickupModeState, setDineInModeState } =
      UIState;
    const updateUiState = (newState: OrderingModeStates): void => {
      if (props.orderingService === OrderingServiceEnum.DELIVERY) {
        setDeliveryModeState(newState);
        return;
      }

      if (props.orderingService === OrderingServiceEnum.DINE_IN) {
        setDineInModeState(newState);
        return;
      }

      setPickupModeState(newState);
    };

    const updateState = async () => {
      // prev state to revert it back if necessary
      const staleState: OrderingModeStates = props.state;

      // next state based on the switcher value
      const newState: OrderingModeStates = isChecked.value
        ? OrderingModeStates.ENABLED
        : OrderingModeStates.DISABLED;

      updateUiState(newState);

      try {
        context.emit("updated", newState);

        await context.root.$apollo.mutate({
          mutation: EDIT_ORDERING_SERVICE_STATE,
          variables: {
            outletId: props.outletId,
            orderingService: props.orderingService,
            state: newState,
          },
        });

        new Toast().create({
          type: "success",
          title: translate("label.success"),
          text: translate("message.record_updated"),
        });
      } catch (error) {
        isChecked.value = !isChecked.value;
        context.emit("updated", staleState);
        updateUiState(staleState);

        new Toast().create({
          type: "error",
          title: translate("label.oops"),
          text: translate("message.general_failure"),
        });
      }
    };

    return {
      // state
      isChecked,

      // actions
      updateState,
    };
  },
});
