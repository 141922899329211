export const OutletPaymentMethodCheckoutComAllowedCountries = [
  "AE",
  "AT",
  "BE",
  "CY",
  "DK",
  "FI",
  "FR",
  "DE",
  "GR",
  "IE",
  "IT",
  "LV",
  "LI",
  "LT",
  "LU",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "GB",
  "US",
  "BH",
  "EG",
  "KW",
  "OM",
  "QA",
  "SA",
];
